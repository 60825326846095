<template>
   <ObiText class="obi-card card border" :class="classList" v-bind="attrs" v-on="$listeners">
      <ObiText class="card-header" v-if="$slots.header">
         <slot name="header" />
      </ObiText>

      <ObiText class="card-body" v-if="!$slots.body">
         <slot />
      </ObiText>

      <ObiText class="card-body p-0" v-if="$slots.body && !hideBody">
         <slot name="body" />
      </ObiText>

      <ObiText class="card-footer" v-if="$slots.footer">
         <slot name="footer" />
      </ObiText>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiCard',

   props: {
      selectable: {
         type: Boolean,
         default: false,
      },
      hideBody: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      classList() {
         const classList = [];

         if (this.selectable !== null) {
            classList.push('--selectable');
         }

         return classList;
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-card {
   border-color: transparent;
   overflow: hidden;
   .card-body {
      overflow-x: hidden;
   }

   .card-header {
      padding: 20px 30px;
      background-color: transparent;
      border-bottom-color: #eeeeee;
   }

   .card-footer {
      padding: 20px 60px;
      background-color: #f7f9fa;
      border-top-color: transparent;
   }

   &.--selectable {
      cursor: pointer;
   }
}
</style>
